/* eslint-disable max-lines */
import React, {
  FC,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { hideScrollBarStyles } from 'common/styles/hideScrollBar'
import { VoidHandler } from 'common/types'
import { isScrolledToBottom } from 'components/page/Chat/Messenger/function/isScrolledToBottom'
import { fullStopPropagation } from 'functions/fullStopPropagation'

import { BOTTOM_SHEET_KEY, BOTTOM_SHEET_VALUE } from './BottomSheet.constants'
import { BottomSheetFooter } from './BottomSheetFooter'
import { BottomSheetTitle } from './BottomSheetTitle'
import { useAnimateContentHeight } from './hooks/useAnimateContentHeight'
import { Loader } from '../Loader/Loader'
import { useScrollbar } from '../Scrollbar/hooks/useScrollbar'
import { Scrollbar } from '../Scrollbar/Scrollbar'
import { breakpoints } from '../shared/breakpoints'
import { applyIfAnimationsEnabledCss } from '../styles/applyIfAnimationsEnabledCss'
import { zIndex } from '../zIndex'

export const BottomSheetInner: FC<{
  title?: ReactNode
  subtitle?: ReactNode
  data: unknown
  open: boolean | null
  setOpen: (open: boolean) => void
  onUnmounted: VoidHandler
  /**
   * Чтобы список не сжимался по высоте.
   * Например при фильтрации списка высота контейнера будет уменьшаться.
   */
  noHeightShrink?: boolean
  /** Если нужно расположить контент по-центру на десктопе */
  centerContentVerticallyOnDesktop?: boolean
  /** Если нужна максимально доступная высота, вместо высоты по контенту */
  forceMaxHeight?: boolean
  mobile: boolean | null
  isIOS: boolean
  children:
    | ReactNode
    | ((params: {
        data: unknown
        contentNode: HTMLDivElement | null
      }) => ReactNode)
  animationsDisabled?: boolean | null
  dataName?: string
  footer?: ReactNode
  boundToContainer?: boolean
}> = ({
  title,
  subtitle,
  data,
  children,
  footer,
  open,
  setOpen,
  onUnmounted,
  noHeightShrink,
  centerContentVerticallyOnDesktop,
  forceMaxHeight,
  mobile,
  isIOS,
  animationsDisabled,
  dataName,
  boundToContainer,
}) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [contentNode, setContentNode] = useState<HTMLDivElement | null>(null)
  const [innerNode, setInnerNode] = useState<HTMLDivElement | null>(null)
  const movingRef = useRef<HTMLDivElement | null>(null)
  const domRect = useRef<DOMRect | undefined>()
  const positionYRef = useRef<number>(0)
  const scrollTopRef = useRef<number>(0)
  const startingScrollTopRef = useRef<number>(0)
  const offsetRef = useRef<number>(0)
  const isDraggingRef = useRef<boolean>(false)
  const isScrolledRef = useRef<boolean>(false)
  const [mounted, setMounted] = useState<boolean>(false)

  const { scrollbarRef } = useScrollbar(contentNode, innerNode)

  useEffect(() => {
    if (open === null) {
      return
    }

    if (open && !mounted) {
      setMounted(true)
      return
    }

    requestAnimationFrame(() => {
      if (!movingRef.current || !overlayRef.current) {
        return
      }

      if (open) {
        domRect.current = movingRef.current.getBoundingClientRect()
        overlayRef.current.style.opacity = OPACITY_SHOW
        movingRef.current.style.transform = TRANSFORM_SHOW
      } else {
        overlayRef.current.style.opacity = OPACITY_HIDE
        movingRef.current.style.transform = TRANSFORM_HIDE

        // Закрываем с помощью таймаута, потому что на iOS не вызывается событие transitionend
        window.setTimeout(() => {
          setMounted(false)
          requestAnimationFrame(() => onUnmounted(true))
        }, ANIMATION_DURATION_MS)
      }
    })
  }, [open, mounted, onUnmounted])

  // Установим аттрибут на документ, чтобы можно было установить на него стили
  useEffect(() => {
    if (mounted) {
      setAttributeToDocument()
    } else {
      removeAttributeFromDocument()
    }
    return () => {
      removeAttributeFromDocument()
    }
  }, [mounted, onUnmounted])

  useEffect(() => {
    if (!movingRef.current || !overlayRef.current || !contentRef.current) {
      return
    }

    const setOverlayColor = (positionY: number) => {
      if (!domRect.current || !overlayRef.current) {
        return
      }
      const shiftPercent = positionY / domRect.current.height
      const opacity = 1 - shiftPercent - 0.6
      overlayRef.current.style.opacity = String(opacity)
    }

    const handleTouchStart = (event: TouchEvent) => {
      if (!movingRef.current || !contentRef.current || !domRect.current) {
        return
      }
      if (!animationsDisabled) {
        movingRef.current.style.transition = TRANSITION_MOVE
      }
      domRect.current = movingRef.current.getBoundingClientRect()
      isDraggingRef.current = true
      const clientY = event.touches[0].clientY
      offsetRef.current = clientY - domRect.current.top
      isScrolledRef.current = contentRef.current.scrollTop !== 0
      startingScrollTopRef.current = scrollTopRef.current
    }

    const handleTouchMove = (event: TouchEvent) => {
      if (!contentRef.current || !movingRef.current) {
        return
      }

      const clientY = event.touches[0].clientY
      const positionY = Math.round(clientY - offsetRef.current)

      const overscrollUp = contentRef.current.scrollTop === 0 && positionY > 0
      const overscrollDown =
        isScrolledToBottom(contentRef.current) && positionY < 0

      if (isIOS && (overscrollUp || overscrollDown)) {
        contentRef.current.style.overflowY = 'hidden'
        // Чтобы не срабатывал pull to refresh на iOS 15 и 16
        // И чтобы нельзя было оттянуть контент вверх, что приводит к багу со скроллом
        // https://youtrack.mamba.ru/issue/M-8320
        event.preventDefault()
      }

      const scrollingContent =
        isScrolledRef.current &&
        contentRef.current.contains(event.target as HTMLElement)

      if (
        !isDraggingRef.current ||
        scrollingContent /** Если это скролл контента - не перемещаем */ ||
        positionY < 0 /** Не даем скроллить выше дозволенного. */ ||
        contentRef.current.scrollTop < 0 /** Отрицательный скролл iOS */
      ) {
        return
      }

      setOverlayColor(positionY)
      positionYRef.current = positionY
      if (!animationsDisabled) {
        movingRef.current.style.transition = TRANSITION_MOVE
      }
      movingRef.current.style.transform = `translate3d(0, ${positionY}px, 0)`
    }

    const handleTouchEnd = (event: TouchEvent) => {
      if (!contentRef.current || !movingRef.current) {
        return
      }
      contentRef.current.style.overflowY = 'scroll'
      if (!animationsDisabled) {
        movingRef.current.style.transition = TRANSITION_START
      }

      const exception = contentRef.current.contains(event.target as HTMLElement)

      // Кейс для iOS: пользователь активно оттягивает прокручивающийся список вниз
      if (
        startingScrollTopRef.current < 0 &&
        scrollTopRef.current < 0 &&
        scrollTopRef.current < startingScrollTopRef.current
      ) {
        setOpen(false)
        return
      }

      if (scrollTopRef.current < -50) {
        setOpen(false)
        return
      }

      if (!isDraggingRef.current || (isScrolledRef.current && exception)) {
        isScrolledRef.current = false
        return
      }

      if (!domRect.current || !movingRef.current) {
        return
      }
      const clientY = event.changedTouches[0].clientY
      const positionY = clientY - offsetRef.current
      const height = domRect.current.height
      const dragged = positionY > height / 5
      const offsetY = dragged ? height : 0
      setOverlayColor(offsetY)

      if (dragged) {
        setOpen(false)
      } else {
        movingRef.current.style.transform = TRANSFORM_SHOW
      }

      isDraggingRef.current = false
    }

    movingRef.current.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
    document.addEventListener('touchend', handleTouchEnd)

    const moving = movingRef.current

    return () => {
      moving.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [animationsDisabled, isIOS, mounted, setOpen])

  useAnimateContentHeight(
    contentNode,
    innerNode,
    noHeightShrink,
    mobile,
    forceMaxHeight
  )

  if (!mounted) {
    return
  }

  const handleCloseClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    fullStopPropagation(event)
    setOpen(false)
  }
  const handleOverlayClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault()
    fullStopPropagation(event)
    setOpen(false)
  }

  /**
   * На десктопе заголовок показываем всегда, потому что там есть крестик закрытия, даже если в заголовке нет текста.
   * Дизайнеры не придумали ничего лучше.
   * На мобилке можно скрыть заголовок, если текста в нем нет. Закрыть боттом шит можно свайпом вниз.
   */
  const emptyTitle = Boolean(title || subtitle)
  const titleContainerVisible = mobile ? emptyTitle : true
  const emptyDesktopHeader = !mobile && !emptyTitle

  const handleContentScroll = (event) => {
    if (!titleRef.current || emptyDesktopHeader) {
      return
    }

    scrollTopRef.current = event.target.scrollTop

    titleRef.current.style.boxShadow =
      event.target.scrollTop > 0 ? '--box-shadow-inset-border' : 'none'
  }

  return (
    <BottomSheetContainer
      data-name={dataName}
      boundToContainer={boundToContainer}
    >
      <BottomSheetOverlay ref={overlayRef} />

      <Moving ref={movingRef} onClick={handleOverlayClick}>
        <BottomSheetInnerContainer
          ref={containerRef}
          onClick={fullStopPropagation}
          className={cx(
            centerContentVerticallyOnDesktop &&
              centerContentVerticallyOnDesktopCss,
            forceMaxHeight && forceMaxHeightCss
          )}
        >
          <Handle />

          {titleContainerVisible && (
            <BottomSheetTitleContainer
              ref={titleRef}
              className={cx(
                // От пустого десктопного заголовка оставляем только крестик и прижимаем его к краю
                emptyDesktopHeader && emptyDesktopTitleCss
              )}
            >
              <BottomSheetTitle title={title} onCloseClick={handleCloseClick} />
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </BottomSheetTitleContainer>
          )}

          <BottomSheetContent
            ref={(node) => {
              // Нужно, для оптимизации (reference equality)
              contentRef.current = node
              // Нужно, чтобы случился еще один рендер
              setContentNode(node)
            }}
            onScroll={handleContentScroll}
          >
            <BottomSheetContentInner
              ref={setInnerNode}
              className={cx(!footer && noFooterCss)}
            >
              {(typeof children === 'function'
                ? children({ data, contentNode })
                : children) || <Loader />}

              {footer && (
                <BottomSheetFooter contentNode={contentNode}>
                  {footer}
                </BottomSheetFooter>
              )}
            </BottomSheetContentInner>
          </BottomSheetContent>
          <Scrollbar ref={scrollbarRef} />
        </BottomSheetInnerContainer>
      </Moving>
    </BottomSheetContainer>
  )
}

const hasAttributeOnDocument = () => {
  return document.documentElement.hasAttribute(BOTTOM_SHEET_KEY)
}
const setAttributeToDocument = () => {
  if (!hasAttributeOnDocument()) {
    document.documentElement.setAttribute(BOTTOM_SHEET_KEY, BOTTOM_SHEET_VALUE)
  }
}
const removeAttributeFromDocument = () => {
  if (hasAttributeOnDocument()) {
    document.documentElement.removeAttribute(BOTTOM_SHEET_KEY)
  }
}

const ANIMATION_DURATION_MS = 300
const TRANSITION_START = `transform ${ANIMATION_DURATION_MS}ms ease-out`
const TRANSITION_MOVE = `transform ${30}ms linear`
const TRANSFORM_SHOW = 'translate3d(0, 0, 0)'
const TRANSFORM_HIDE = 'translate3d(0, 100%, 0)'
const OPACITY_SHOW = '0.4'
const OPACITY_HIDE = '0'

export const BottomSheetContainer = styled.div<{ boundToContainer?: boolean }>`
  position: ${({ boundToContainer }) =>
    boundToContainer ? 'absolute' : 'fixed'};
  inset: 0;
  z-index: ${zIndex.default};
`
const Handle = styled.div`
  position: absolute;
  top: -12px;
  width: 48px;
  height: 4px;
  border-radius: 2.5px;
  opacity: 0.48;
  background: var(--warm-hard);
  background-blend-mode: overlay;

  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`
export const BottomSheetOverlay = styled.div`
  position: fixed;
  inset: 0;
  touch-action: none;
  opacity: ${OPACITY_HIDE};
  ${applyIfAnimationsEnabledCss(`
    transition: opacity ${ANIMATION_DURATION_MS}ms ease-out;
    will-change: opacity;
  `)};
  background-color: var(--static-black);
`
const Moving = styled.div`
  position: absolute;
  inset: 0;
  touch-action: none;
  transform: ${TRANSFORM_HIDE};
  ${applyIfAnimationsEnabledCss(`
    will-change: transform;
    transition: ${TRANSITION_START};
  `)};
`
export const BottomSheetTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  background: var(--static-white);
  border-radius: 32px 32px 0 0;
  ${applyIfAnimationsEnabledCss(`
    transition: box-shadow ${ANIMATION_DURATION_MS}ms;
    will-change: box-shadow;
  `)};
  padding: var(--spacing-32px) var(--spacing-16px) var(--spacing-12px)
    var(--spacing-16px);

  @media screen and (min-width: ${breakpoints.mobile}px) {
    padding: var(--spacing-40px) var(--spacing-40px) var(--spacing-16px)
      var(--spacing-40px);
  }
`
const emptyDesktopTitleCss = css`
  position: absolute;
  top: 0;

  html[dir='ltr'] & {
    right: 0;
  }

  html[dir='rtl'] & {
    left: 0;
  }

  z-index: 1;
  background: transparent;
  width: auto;
`
const Subtitle = styled.div`
  margin-top: var(--spacing-24px);
`
export const BottomSheetInnerContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 90%;
  border-radius: 32px 32px 0 0;
  background: var(--background-surface-1);

  @media screen and (min-width: ${breakpoints.mobile}px) {
    min-height: 100%;
    height: 100%;
  }
`
export const BottomSheetContent = styled.div`
  width: 100%;
  position: relative;
  overflow-y: auto;
  background-color: var(--static-white);
  ${applyIfAnimationsEnabledCss(`
    transition: all 500ms ease-in-out;
  `)};
  border-radius: 32px 32px 0 0;
  -webkit-overflow-scrolling: touch;
  ${hideScrollBarStyles};

  @media screen and (min-width: ${breakpoints.mobile}px) {
    height: 100%;
  }
`
const BottomSheetContentInner = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: var(--spacing-24px);
`
const noFooterCss = css`
  padding-bottom: var(--spacing-24px);
`
const centerContentVerticallyOnDesktopCss = css`
  @media screen and (min-width: ${breakpoints.mobile}px) {
    ${BottomSheetTitleContainer} {
      position: absolute;
      z-index: 1;
      background: transparent;
    }
    ${BottomSheetContent}, ${BottomSheetContentInner} {
      height: 100%;
    }
  }
`
const forceMaxHeightCss = css`
  height: 100%;
`
